<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <div id="favorites_anim"></div>
    </v-row>
  </v-container>
</template>

<script>
import lottie from 'lottie-web'
export default {
  name: 'Favorites',
  data: () => ({
    anim: null
  }),
  mounted () {
    this.$store.commit('updateRoute', this.$route.name)
    this.loadAnim()
  },
  beforeDestroy () {
    this.anim.destroy()
  },
  methods: {
    loadAnim () {
      this.anim = lottie.loadAnimation({
        container: document.getElementById('favorites_anim'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '/lottie/empty-box.json'
      })
    }
  }
}
</script>

<style scoped>

</style>
